import { default as NextLink } from 'next/link';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FooterLinks, Maybe } from '../../types/contentstack';
import { mediaScreen } from '../../utils/mediaScreen';
import { Col, ResponsiveContainer, Row } from '../responsive';

export const DarkFooter: FunctionComponent<IDarkFooter> = ({
  links,
  withAngle = false,
}) => {
  return (
    <FullWidthDelimiter>
      {!!withAngle && (
        <img
          src="/img/dark-footer-background.svg"
          width="500"
          height="304"
          alt="dark footer background"
        />
      )}

      <WidthDelimiter>
        <Container>
          <img
            src="/img/dark-footer-line.svg"
            width="1335"
            height="2"
            alt="dark footer line"
            className="line"
          />

          <Content>
            <Row>
              <Col md={6} className="logo-container">
                <img
                  src="/img/header-logo.svg"
                  width="199"
                  height="62"
                  alt="mapped logo"
                />

                <Copyright className="desktop-only">
                  © Mapped {new Date().getFullYear()}. All rights reserved.
                  <br />
                  SOC compliant.
                </Copyright>
              </Col>

              <Col md={3}>
                {links?.map((link) => {
                  return (
                    <NextLink
                      className="link"
                      key={link?.href}
                      href={link?.href!}
                      target={
                        link?.href?.startsWith('http') ? '_blank' : '_self'
                      }
                    >
                      {link?.title}
                    </NextLink>
                  );
                })}
              </Col>

              <Col md={3}>
                <Social>
                  <a
                    href="https://www.linkedin.com/company/mapped/"
                    target="_blank"
                  >
                    <img
                      src="/img/dark-footer-linkedin.svg"
                      width="32"
                      height="32"
                      alt="linkedin icon"
                    />
                  </a>

                  <a href="https://twitter.com/Mapped" target="_blank">
                    <img
                      src="/img/dark-footer-twitter.svg"
                      width="32"
                      height="32"
                      alt="twitter icon"
                    />
                  </a>

                  <a href="https://github.com/mapped" target="_blank">
                    <img
                      src="/img/dark-footer-github.svg"
                      width="32"
                      height="32"
                      alt="github icon"
                    />
                  </a>
                </Social>

                <Compliance>
                  <a
                    href="https://www.prescientsecurity.com/iso-27001-certification-journey"
                    target="_blank"
                    title="ISO 27001 certified"
                  >
                    <img
                      src="/img/iso-logo.png"
                      width={120}
                      height={120}
                      alt="ISO 27001 certified"
                    />
                  </a>

                  <a
                    href="http://www.aicpa.org/soc4so"
                    target="_blank"
                    title="SOC compliant"
                  >
                    <img
                      src="/img/soc-logo.png"
                      width={120}
                      height={120}
                      alt="SOC compliant"
                    />
                  </a>
                </Compliance>

                <Copyright className="mobile-only">
                  © Mapped {new Date().getFullYear()}. All rights reserved.
                  <br />
                  SOC compliant.
                </Copyright>
              </Col>
            </Row>
          </Content>
        </Container>
      </WidthDelimiter>
    </FullWidthDelimiter>
  );
};

const FullWidthDelimiter = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 1600px;
  margin: 0 auto;
  margin-top: 100px;

  > img {
    width: 100%;
  }

  .desktop-only {
    display: block;
  }

  .mobile-only {
    display: none;
  }

  ${mediaScreen('xs', 'md')} {
    .desktop-only {
      display: none;
    }

    .mobile-only {
      display: block;
    }
  }
`;

const WidthDelimiter = styled.footer`
  width: 100%;
  max-width: 1600px;
  position: relative;
  margin: 0 auto;
`;

const Container = styled.div`
  width: 100%;

  .line {
    position: relative;
    top: -1px;
    left: -10px;
    width: 100%;
    z-index: 10;
    display: inline;
  }

  .link {
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.2px;
    font-weight: bold;
    margin-bottom: 16px;
    cursor: pointer;
    display: block;

    &:hover {
      text-decoration: underline;
    }

    ${mediaScreen('xs', 'md')} {
      text-align: center;
    }
  }
`;

const Copyright = styled.p`
  margin: 0;
  margin-top: 30px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.2px;
  color: ${(props) => props.theme.colors.white};

  ${mediaScreen('xs', 'md')} {
    margin-top: 0px;
    text-align: center;
  }
`;

const Content = styled(ResponsiveContainer)`
  padding: 5% 0;

  ${mediaScreen('xs', 'md')} {
    > div {
      flex-direction: column;
      align-items: center;

      > div {
        width: auto;
        margin: 5% 0;
      }
    }

    .logo-container {
      text-align: center;
      margin-top: 30px;

      img {
        margin: 0 auto;
      }
    }
  }
`;

const Social = styled.div`
  display: flex;
  justify-content: flex-end;

  a {
    display: block;
    text-decoration: none !important;
    margin-left: 32px;

    ${mediaScreen('xs', 'md')} {
      margin: 24px;
    }
  }

  img {
    width: 32px;
    height: 32px;
    cursor: pointer;
    pointer-events: all;

    &:hover {
      opacity: 0.6;
    }
  }

  ${mediaScreen('xs', 'md')} {
    justify-content: center;
  }
`;

const Compliance = styled.div`
  display: flex;
  justify-content: center;

  ${mediaScreen('md')} {
    justify-content: flex-end;
    padding-top: 30px;
    padding-bottom: 0;

    a:last-of-type {
      margin-left: 20px;
      margin-right: -10px;
    }
  }

  ${mediaScreen('xs', 'md')} {
    padding: 20px 0;
    flex-direction: column;
    align-items: center;

    a:last-of-type {
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }
`;

interface IDarkFooter {
  links?: Maybe<Maybe<FooterLinks>[]>;
  withAngle?: boolean;
}
