import { forwardRef, ForwardRefRenderFunction } from 'react';

const SeparatorComponent: ForwardRefRenderFunction<
  any,
  { size?: 'tiny' | 'regular' }
> = ({ size = 'regular', ...props }, ref) => {
  if (size === 'tiny') {
    return (
      <img
        src="/img/separator-tiny.svg"
        width="40"
        height="8"
        className="separator tiny"
      />
    );
  }

  return (
    <img
      ref={ref}
      {...(props as any)}
      size={size}
      alt="separator line"
      src={`/img/separator.svg`}
      width="768"
      height="8"
      style={{ maxWidth: '90%' }}
      className={`separator regular`}
    />
  );
};

export const Separator = forwardRef(SeparatorComponent);
