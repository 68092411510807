import { gql } from '@apollo/client';
import { GetServerSideProps, NextPage } from 'next';
import Link from 'next/link';
import styled from 'styled-components';
import { FOOTER_QUERY } from '../../components/footer';
import { DarkFooter } from '../../components/footer/darkFooter';
import { HEADER_QUERY, Header } from '../../components/header';
import { Col, ResponsiveContainer, Row } from '../../components/responsive';
import { Button } from '../../components/ui/button';
import { OptimizedImage } from '../../components/ui/optimizedImage';
import { Separator } from '../../components/ui/separator';
import { ContentStackClient } from '../../lib/contentstack';
import { AllUseCase, UseCase } from '../../types/contentstack';
import { IPageProps } from '../../types/page';
import { getAssetUrl } from '../../utils/contentstack';
import { mediaScreen } from '../../utils/mediaScreen';

export const Page: NextPage<IPageProps<UseCase>> = ({
  header,
  page,
  footer,
}) => {
  return (
    <>
      <Header
        pageTitle={page?.title}
        pageDescription={page?.description}
        {...header}
      />

      <Hero>
        <HeroImage>
          <div>
            <OptimizedImage
              src={getAssetUrl(page?.hero?.imageConnection)}
              alt="hero image"
              fill={true}
            />
          </div>
        </HeroImage>

        <HeroContent>
          <ResponsiveContainer>
            <HeroTitle
              dangerouslySetInnerHTML={{
                __html: page?.hero?.title?.replace(
                  new RegExp(page?.short_title!, 'gi'),

                  (match) =>
                    `<strong style="color: ${page?.short_title_color};">${match}</strong>`
                )!,
              }}
            />

            <HeroText
              dangerouslySetInnerHTML={{
                __html: page?.hero?.text!,
              }}
            />

            <Link href={page?.hero?.button?.href || ''}>
              <Button mColor="teal">{page?.hero?.button?.title}</Button>
            </Link>
          </ResponsiveContainer>
        </HeroContent>
      </Hero>

      <ColumnSet>
        <ResponsiveContainer>
          <SectionTitle style={{ textAlign: 'center' }}>
            {page?.examples?.title}
          </SectionTitle>

          <SectionText
            style={{ textAlign: 'justify' }}
            dangerouslySetInnerHTML={{
              __html: page?.examples?.text!,
            }}
          />

          <div style={{ marginTop: '90px' }}>
            <Separator />
          </div>

          <Row key={1} style={{ textAlign: 'center' }}>
            {page?.examples?.items?.map((item) => {
              return (
                <Col key={item?.title} xs={12} md={3}>
                  <ItemImage>
                    <img
                      src={getAssetUrl(item?.imageConnection)}
                      className="image"
                    />
                  </ItemImage>
                  <ListItemTitle>{item?.title}</ListItemTitle>
                  <SectionSubText
                    dangerouslySetInnerHTML={{ __html: item?.text! }}
                  />
                </Col>
              );
            })}
          </Row>

          <div>
            <Separator />
          </div>
        </ResponsiveContainer>
      </ColumnSet>

      {page?.sections?.map((section, idx) => {
        return (
          <Section key={section?.title} direction={idx % 2 ? 'ltr' : 'rtl'}>
            <ResponsiveContainer>
              <Row className="row">
                <Col md={6}>
                  <Separator size="tiny" />

                  <SectionSubTitle>{section?.title}</SectionSubTitle>
                  <SectionSubText
                    dangerouslySetInnerHTML={{ __html: section?.text! }}
                    style={{ textAlign: 'justify' }}
                  />
                </Col>

                <Col md={6} className="image">
                  <img src={getAssetUrl(section?.imageConnection)} />
                </Col>
              </Row>
            </ResponsiveContainer>
          </Section>
        );
      })}

      <DarkFooter withAngle={false} links={footer?.links} />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ params }) => {
  try {
    const id = params?.id as string[];
    const url = `/cases/${id?.join('/')}`;

    const { data } = await ContentStackClient().query<IPageProps<AllUseCase>>({
      query: PAGES_QUERY,
    });

    const pageData = data?.page?.items?.find((item) => item?.url === url);

    if (!pageData || pageData?.home_hero_only) {
      return {
        notFound: true,
        props: {},
      };
    }

    return {
      props: { ...data, page: pageData },
    };
  } catch (e: any) {
    return { props: {} };
  }
};

const PAGES_QUERY = gql`
  query useCaseQuery {
    ${HEADER_QUERY}
    ${FOOTER_QUERY}

    page: all_use_case {
      items {
        title
        description
        short_title
        short_title_color
        home_hero_only
        url
        hero {
          title
          text
          button {
            title
            href
          }
          imageConnection {
            edges {
              node {
                url
              }
            }
          }
        }
        examples {
          title
          text
          items {
            imageConnection {
              edges {
                node {
                  url
                }
              }
            }
            title
            text
          }
        }
        sections {
          title
          text
          imageConnection {
            edges {
              node {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const Hero = styled.div`
  width: 100%;
  margin-top: 80px;
  position: relative;
  margin-bottom: 80px;
`;

const HeroImage = styled.div`
  position: absolute;
  max-width: 1600px;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: 0 auto;
  overflow: hidden;

  > div {
    height: 100%;
    width: 100%;
    transition: opacity 1s ease;
    position: absolute;
    top: 0;
    left: 0;

    .image {
      height: 100%;
      width: 100%;

      img {
        object-fit: cover;
      }
    }
  }
`;

const HeroContent = styled.div`
  padding: 200px 0;
  position: relative;
  z-index: 1;
  width: 100%;
  background: rgba(25, 31, 44, 0.7);

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${mediaScreen('xs', 'sm')} {
    padding: 100px 0;
  }
`;

const HeroTitle = styled.h1`
  color: white;
  font-size: 70px;
  font-weight: 500;
  line-height: 1.3;
  margin-top: 0;
  text-align: center;
  margin-bottom: 0;

  ${mediaScreen('xs', 'sm')} {
    font-size: 46px;
    font-weight: 500;
  }
`;

const HeroText = styled.p`
  color: white;
  font-size: 22px;
  line-height: 32px;
  margin-top: 24px;
  max-width: 690px;
  font-weight: 400;
  margin-bottom: 32px;
  text-align: center;

  ${mediaScreen('xs', 'sm')} {
    font-size: 17px;
    line-height: 24px;
    margin-top: 24px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 50px;
  line-height: 1.2;
  color: white;
  font-weight: bold;
  margin-top: 0;

  ${mediaScreen('xs', 'sm')} {
    font-size: 29px;
  }

  ${mediaScreen('sm', 'md')} {
    font-size: 40px;
  }
`;

const ListItemTitle = styled.h3`
  margin-top: 15px;
  margin-bottom: 16px;
  font-size: 28px;
  color: white;
  font-weight: bold;

  ${mediaScreen('xs', 'sm')} {
    font-size: 24px;
  }
`;

const SectionText = styled.p`
  color: white;
  font-size: 22px;
  line-height: 30px;

  ${mediaScreen('xs', 'sm')} {
    font-size: 17px;
    line-height: 24px;
  }

  ${mediaScreen('sm', 'md')} {
    font-size: 19px;
    line-height: 25px;
  }
`;

const SectionSubTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 38px;
  line-height: 1.16;
  color: white;
  font-weight: bold;

  ${mediaScreen('xs', 'sm')} {
    font-size: 24px;
  }

  ${mediaScreen('sm', 'md')} {
    font-size: 30px;
  }
`;

const SectionSubText = styled.p`
  color: white;
  font-size: 16px;
  line-height: 1.5;

  ${mediaScreen('xs', 'sm')} {
    font-size: 14px;
  }

  ${mediaScreen('sm', 'md')} {
    font-size: 16px;
  }
`;

const ColumnSet = styled.div`
  .row {
    margin: 80px 0 80px 0;
    justify-content: space-between;

    ${mediaScreen('xs', 'md')} {
      justify-content: none;
      margin-bottom: 60px;

      img {
        margin: 0 auto;
      }

      > div:not(:last-of-type) {
        margin-bottom: 80px;
      }
    }
  }
`;

const ItemImage = styled.div`
  display: flex;
  align-items: flex-end;
  text-align: center;

  img {
    margin: 0 auto;
  }

  ${mediaScreen('xs', 'md')} {
    min-height: auto;
  }
`;

const Section = styled.div<{ direction: 'ltr' | 'rtl' }>`
  margin-top: 120px;

  .row {
    align-items: center;
    flex-direction: ${({ direction }) =>
      direction === 'ltr' ? 'row' : 'row-reverse'};

    .image {
      display: flex;
      justify-content: ${({ direction }) =>
        direction === 'ltr' ? 'flex-end' : 'flex-start'};

      img {
        border-radius: 3%;
        width: 65%;
      }
    }
  }

  ${mediaScreen('xs', 'md')} {
    .row {
      flex-direction: column;

      .image {
        justify-content: center;
        margin: 50px auto 0;

        img {
          width: 100%;
        }
      }
    }
  }
`;

export default Page;
