import { jsonToHtml } from '@contentstack/json-rte-serializer';
import { parse as parseHTML } from 'node-html-parser';
import { Maybe, SysAssetConnection } from '../types/contentstack';

export function getAssetUrl(
  connection?: Maybe<SysAssetConnection> | undefined
) {
  return connection?.edges?.[0]?.node?.url || '';
}

export function renderRichText(json: any, plainText?: boolean) {
  const html = jsonToHtml(json?.json || json)

  if (!plainText) {
    return html
  }

  return parseHTML(html)?.textContent
}