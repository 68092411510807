import Image, { ImageProps } from 'next/image';
import LegacyImage from 'next/legacy/image';
import { FunctionComponent } from 'react';
import styled, { CSSProperties } from 'styled-components';

export const OptimizedImage: FunctionComponent<
  Omit<ImageProps, 'alt'> & { style?: CSSProperties; alt?: string }
> = ({ className, style, ...props }) => {
  const ImageComponent = props.layout ? LegacyImage : Image;

  return (
    <Container className={`optimized image ${className ?? ''}`} style={style}>
      <ImageComponent {...(props as any)} />
    </Container>
  );
};

const Container = styled.div`
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  margin: 0;
  pointer-events: none;
  user-select: none;
`;
